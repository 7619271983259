.add-to-calendar-button {
    button {
        width: 100%;
        display: flex;
        padding: 16px 20px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        border-radius: 400px;
        background: #060A12;
        border: none;
        cursor: pointer;

        .button-text {
            color: #EAFFF7;
            text-align: center;
            // font-family: "SuisseIntl";
            font-size: 24px;
            font-style: normal;
            font-weight: 450;
            line-height: 24px; /* 100% */
            letter-spacing: 0.048px;
        }
    }

    .popup {
        position: absolute;
        background-color: #060A12;
        z-index: 1000;
        margin-top: 16px;
        border-radius: 24px;

        div {
            display: flex;
            width: 258px;
            padding: 48px;
            flex-direction: column;
            align-items: flex-start;
            gap: 48px;
            box-sizing: border-box;

            a,
            button {
                display: flex;
                height: 24px;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 16px;
                align-self: stretch;
                color: #EAFFF7;
                text-align: center;
                font-size: 24px;
                font-style: normal;
                font-weight: 450;
                line-height: 24px; /* 100% */
                text-decoration: none;
                background-color: #060A12;
                padding: 0;
            }
        }
    }
}

/* Extra Small */
@media (max-width: 660px) {
    .add-to-calendar-button {
        button {
    
            .button-text {
                font-size: 16px;
            }
        }

        .popup {
    
            div {
                width: calc(100vw - 48px);
                gap: 24px;
                padding: 24px;

                a,
                button {
                    font-size: 16px;
                }
            }
        }
    }
}