.countdown {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;

    .metric-wrapper {
        display: flex;
        flex-direction: column;
        font-size: 32px;
        padding: 48px;
        gap: 16px;

        .value {
            color: #060A12;
            text-align: center;
            // font-family: "SuisseIntl";
            font-size: 128px;
            font-style: normal;
            font-weight: 400;
            line-height: 128px; /* 100% */
        }

        .metric {
            color: #060A12;
            text-align: center;

            /* Reading-Medium */
            // font-family: "SuisseIntl";
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px; /* 133.333% */
        }
    }
}

/*** Responsive Styles: Desktop First ***/

/* Desktop */
@media (max-width: 1199px) {}

/* Tablet */
@media (max-width: 991px) {}

/* Mobile */
@media (max-width: 834px) {}

/* Extra Small */
// @media (max-width: 575px) {
@media (max-width: 660px) {
    .countdown {
        display: flex;
        gap: 16px;
        justify-content: center;
        align-items: center;
    
        .metric-wrapper {
            font-size: 32px;
            padding: 16px;
    
            .value {
                font-size: 64px;
                line-height: 64px; /* 100% */
            }
    
            .metric {
                font-size: 16px;
                font-weight: 450;
                line-height: 16px; /* 133.333% */
            }
        }
    }
}

/* Super Small */
@media (max-width: 390px) {
    .countdown {
        gap: 4px;
    }
}