.coming-soon-page {
    width: 100vw;
    height: 100%;
    min-height: 100vh;

    .header-blur-wrapper {
        position: fixed; 
        top: 0;
        width: 100%; 
        height: 33vh; 
        border-radius: 0px;
        pointer-events: none;

        .header-blur {
            position: relative; 
            width: 100%; 
            height: 100%;
            border-radius: 0px;
            pointer-events: none;

            .header-blur-1 {
                position: absolute; 
                inset: 0px; 
                z-index: 1; 
                backdrop-filter: blur(0.0625px); 
                -webkit-backdrop-filter: blur(0.0625px); 
                mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12.5%, rgb(0, 0, 0) 25%, rgba(0, 0, 0, 0) 37.5%); 
                border-radius: 0px; 
                pointer-events: none;
                background-color: rgba(234, 255, 247, 0);
            }
            .header-blur-2 {
                position: absolute; 
                inset: 0px; 
                z-index: 2; 
                backdrop-filter: blur(0.125px); 
                -webkit-backdrop-filter: blur(0.125px); 
                mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 12.5%, rgb(0, 0, 0) 25%, rgb(0, 0, 0) 37.5%, rgba(0, 0, 0, 0) 50%); 
                border-radius: 0px; 
                pointer-events: none;
                background-color: rgba(234, 255, 247, 0);
            }
            .header-blur-3 {
                position: absolute; 
                inset: 0px; 
                z-index: 3; 
                backdrop-filter: blur(0.25px); 
                -webkit-backdrop-filter: blur(0.25px); 
                mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 25%, rgb(0, 0, 0) 37.5%, rgb(0, 0, 0) 50%, rgba(0, 0, 0, 0) 62.5%); 
                border-radius: 0px; 
                pointer-events: none;
                background-color: rgba(234, 255, 247, 0);
            }
            .header-blur-4 {
                position: absolute; 
                inset: 0px; 
                z-index: 4; 
                backdrop-filter: blur(0.5px); 
                -webkit-backdrop-filter: blur(0.5px); 
                mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 37.5%, rgb(0, 0, 0) 50%, rgb(0, 0, 0) 62.5%, rgba(0, 0, 0, 0) 75%); 
                border-radius: 0px; 
                pointer-events: none;
                background-color: rgba(234, 255, 247, 0);
            }
            .header-blur-5 {
                position: absolute; 
                inset: 0px; 
                z-index: 5; 
                backdrop-filter: blur(1px); 
                -webkit-backdrop-filter: blur(1px); 
                mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 50%, rgb(0, 0, 0) 62.5%, rgb(0, 0, 0) 75%, rgba(0, 0, 0, 0) 87.5%); 
                border-radius: 0px; 
                pointer-events: none;
                background-color: rgba(234, 255, 247, 0.05);
            }
            .header-blur-6 {
                position: absolute; 
                inset: 0px; 
                z-index: 6; 
                backdrop-filter: blur(2px); 
                -webkit-backdrop-filter: blur(2px); 
                mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 62.5%, rgb(0, 0, 0) 75%, rgb(0, 0, 0) 87.5%, rgba(0, 0, 0, 0) 100%); 
                border-radius: 0px; 
                pointer-events: none;
                background-color: rgba(234, 255, 247, 0.05);
            }
            .header-blur-7 {
                position: absolute; 
                inset: 0px; 
                z-index: 7; 
                backdrop-filter: blur(4px); 
                -webkit-backdrop-filter: blur(4px); 
                mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 75%, rgb(0, 0, 0) 87.5%, rgb(0, 0, 0) 100%); 
                border-radius: 0px; 
                pointer-events: none;
                background-color: rgba(234, 255, 247, 0.1);
            }
            .header-blur-8 {
                position: absolute; 
                inset: 0px; 
                z-index: 8; 
                backdrop-filter: blur(8px); 
                -webkit-backdrop-filter: blur(8px); 
                mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 87.5%, rgb(0, 0, 0) 100%); 
                border-radius: 0px; 
                pointer-events: none;
                background-color: rgba(234, 255, 247, 0.1);
            }

        }
    
    }

    .page-title {
        width: 100%;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        .main-title {
            color: var(--text);
            text-align: center;
            font-size: 128px;
            font-style: normal;
            font-weight: 500;
            line-height: 128px;
            /* 100% */
            text-align: left;
        }
    }

    .intro {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: flex-start;
        justify-content: space-between;
        gap: 16px;

        .bold {
            font-weight: 600;
        }

        p {
            display: flex;
            flex-direction: column;
            gap: 8px;
            color: var(--text);
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 133.333% */
        }
    }

    .logos-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;

        .logo-container {
            display: flex;
            flex: 1;
            gap: 16px;
            align-items: center;
            justify-content: center;

            img {
            }
        }
    }


    .add-calendar-button-mobile {
        display: none;
    }

    .add-calendar-button {
        display: block;
    }

    .countdown-container {
        // margin-top: calc(100vh - 262px - 208px - 272px);
        height: 512px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    // Gradient
    .gradient-top {
        height: 597px;
        background: linear-gradient(180deg, rgba(0, 221, 49, 0.00) 0%, #00DD31 100%);
    }

    .gradient-middle {
        height: 100vh;
        background-color: var(--highlight);
    }

    .gradient-bottom {
        height: 288px;
        background: linear-gradient(360deg, rgba(0, 221, 49, 0.00) 0%, #00DD31 100%);
        padding: 48px;
    }

    .gradient-footer {
        height: 288px;
        background: linear-gradient(180deg, rgba(0, 221, 49, 0.00) 0%, #00DD31 100%);
        padding: 48px;
    }

    .agenda-wrapper {
        display: flex;
        flex-direction: row;
        gap: 16px;
        padding: 0px 48px;
        align-items: flex-start;

        .title {
            width: var(--section-title-lg);
            padding: 48px 0px;
        }

        .details {
            width: 100%;

            .row {
                display: flex;
                flex-direction: row;
                gap: 16px;
                padding: 48px 0px;
                align-items: flex-start;
                align-self: stretch;

                &.table-header {
                    border-bottom: 1px solid #000;
                }

                .time-wrapper {
                    width: 211px;

                    p {
                        color: var(--text);
                    }
                }

                .session-wrapper {
                    p {
                        color: var(--text);
                    }
                }
            }
        }
    }

    .experts {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
        gap: 16px;
        align-items: flex-start;

        .description-wrapper {
            width: var(--section-title-lg);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            flex-shrink: 0;

            p {
                color: var(--text);

                /* Reading-Regular */
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 32px;
                /* 133.333% */
            }
        }

        .details-wrapper {
            display: flex;
            flex-direction: column;
            gap: 48px;

            .company-wrapper {
                display: flex;
                flex-direction: column;
                gap: 24px;

                .names-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 24px;
                    align-self: stretch;

                    .name-details {
                        display: flex;
                        flex-direction: column;
                        gap: 0;

                        h1 {
                            color: var(--text);
                        }

                        p {
                            color: var(--text);
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 450;
                            line-height: 32px;
                        }

                    }
                }
            }
        }
    }

    .address {
        display: flex;
        flex-direction: column;
        gap: 0;

        .header-wrapper {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;

            .title-container {
                width: var(--section-title-lg);
                min-width: var(--section-title-lg);
            }
        }

        .details-wrapper {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 16px;
            align-self: stretch;

            .address-details-wrapper {
                width: 437px;
                display: flex;
                flex-direction: column;
                gap: 16px;

                p {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }

                .map-link {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 16px;
                    color: var(--text);
                    text-decoration: none;
                }
            }

            .map-wrapper {
                width: 100%;

                iframe {
                    width: 100% !important;
                }
            }
        }
    }

    footer {
        display: flex;
        padding: 48px;
        flex-direction: column;
        align-items: flex-start;
        gap: 48px;
        align-self: stretch;
        background: #00DD31;

        .img-container {}

        .nav-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            p {
                color: var(--text);
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                /* 150% */
            }

            .imprint-link {
                color: var(--text);
                font-size: 16px;
                font-style: normal;
                font-weight: 450;
                line-height: 24px;
                /* 150% */
                text-decoration: none;
            }
        }
    }
}

/*** Responsive Styles: Desktop First ***/

/* Desktop */
@media (max-width: 1199px) {
    .coming-soon-page {
        .page-title {

            .main-title {
                font-weight: 400;
            }
        }
    }
}

/* Tablet */
@media (max-width: 991px) {
    .coming-soon-page {

        .intro {
            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
        .agenda-wrapper {
            .title {
                width: var(--section-title-md);
            }
        }

        .experts {
            .description-wrapper {
                width: var(--section-title-md);
            }
        }

        .address {
            .header-wrapper {
                .title-container {
                    width: var(--section-title-md);
                    min-width: var(--section-title-md);
                }
            }
        }
    }
}

/* Mobile */
@media (max-width: 834px) {
    .coming-soon-page {

        .intro {
            .timeline {
                display: none;
            }
        }

        .agenda-wrapper {
            .title {
                width: var(--section-title-sm);
            }
        }

        .experts {
            .description-wrapper {
                width: var(--section-title-sm);
            }
        }

        .address {
            .header-wrapper {
                .title-container {
                    width: var(--section-title-sm);
                    min-width: var(--section-title-sm);
                }
            }
        }
    }
}

/* Extra Small */
// @media (max-width: 575px) {
@media (max-width: 660px) {
    .coming-soon-page {
        overflow: hidden;

        .header-blur-wrapper {
            height: 22vh; 
        }

        header {
            h1 {
                font-size: 32px;
                line-height: 32px;
            }
        }

        .page-title {
            height: auto;

            .main-title {
                font-size: 64px;
                line-height: 64px;
            }

            h1 {
                font-size: 32px;
                line-height: 32px;
            }
        }

        .countdown-container {
            height: 422px;
        }
        
        .intro {
            flex-direction: column;
    
            p {
                gap: 4px;
            }
        }

        .logos-wrapper {
            // height: 44px;
            .logo-container {

                img {
                    // height: 100%;
                }

                .silpion-logo {
                    height: 16px;
                }
    
                .teams-logo {
                    height: 44px;
                }
    
                .hoyer-logo {
                    height: 8px;
                }

            }
        }

        .add-calendar-button-mobile {
            display: block;
            width: 100%;
        }

        .add-calendar-button {
            display: none;
        }

        .gradient-footer {
            height: 144px;
        }

        .gradient-bottom {
            height: 174px;
        }

        .agenda-wrapper {
            flex-direction: column;
            gap: 24px;
            padding: 0px 24px;
    
            .title {
                width: var(--section-title-lg);
                padding: 0px;
            }
    
            .details {
                width: 100%;
    
                .row {
                    display: flex;
                    flex-direction: row;
                    gap: 16px;
                    padding: 48px 0px;
                    align-items: flex-start;
                    align-self: stretch;
    
                    &.table-header {
                        border-bottom: 1px solid #000;
                    }
    
                    .time-wrapper {
                        width: 133px;
    
                        p {
                            color: var(--text);
                        }
                    }
    
                    .session-wrapper {
                        p {
                            color: var(--text);
                        }
                    }
                }
            }
        }

        .experts {

            .description-wrapper {

                h4 {
                    font-size: 16px;
                }

                p {}
            }

            .details-wrapper {
                padding-top: 48px;

                .company-wrapper {
                    .title {
                        font-size: 16px;
                    }

                    .names-wrapper {

                        .name-details {

                            h1 {
                                font-size: 24px;
                                line-height: 24px;
                                font-weight: 600;
                            }

                            p {
                                font-size: 16px;
                                line-height: 16px;
                            }

                        }
                    }
                }
            }
        }

        .address {
            .header-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;

                .title-container {
                    width: 100%;

                    h4 {
                        font-size: 16px;
                    }
                }

                .description-container {

                    p {
                        font-size: 16px;
                    }
                }
            }

            .details-wrapper {
                flex-direction: column-reverse;

                .address-details-wrapper {

                    p {
                        font-size: 16px;
                        line-height: 24px;
                    }

                    .map-link {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: 390px) {
    .coming-soon-page {
        .experts {
            .description-wrapper {
                width: 80px;
            }
        }
    }
}