.landing-page {
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 48px;

    header {}

    .page-body {
    }

    footer {}
}