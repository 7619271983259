/* Font Family */
@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-Ultralight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-UltralightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-Book.otf') format('opentype');
  font-weight: 450;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-BookItalic.otf') format('opentype');
  font-weight: 450;
  font-style: italic;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SuisseIntl';
  src: url('./assets/fonts/Suisse/SuisseIntl-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

/* Variables */
:root {
  --highlight: #00DD31;
  /* --highlight: pink; */
  --background: rgb(234, 255, 247);
  --text: #121212;
  --section-title-lg: 437px;
  --section-title-md: 357px;
  --section-title-sm: 50%;
}

/* Global Styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'SuisseIntl', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 100% */
}

h2 {
  font-size: 56;
}

h3 {
  font-size: 48px;
}

h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

p {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
}

a {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 100% */
}

.component {
  padding: 48px;
  box-sizing: border-box;
}

.spacer {
  width: 100%;
  height: 48px;
}

header {
  color: var(--highlight);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: sticky;
  top: 0;
  z-index: 100;
}

header h1 {
  display: flex;
  flex-direction: column;
}

header .header-color-text {
  color: var(--highlight);
}

.atcb-saved .atcb-checkmark {
  display: none !important;
}

#atcb-reference {
  display: none !important;
}

/*** Responsive Styles: Desktop First ***/

/* Desktop */
@media (max-width: 1199px) {}

/* Tablet */
@media (max-width: 991px) {}

/* Mobile */
@media (max-width: 767px) {}

/* Extra Small */
@media (max-width: 660px) {
  .component {
    padding: 24px;
  }
}